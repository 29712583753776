import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fetchFeatureFlags, addFeatureFlag, updateFeatureFlag } from './actions';
import { type FeatureFlag } from '@/types/FeatureFlag';

export interface FeatureFlagsSliceState {
  featureFlags: FeatureFlag[];
  loading: boolean;
  isLoadingAddFlag: boolean;
  successfulFlagAddition: boolean;
}

const initialState: FeatureFlagsSliceState = {
  featureFlags: [],
  loading: false,
  isLoadingAddFlag: false,
  successfulFlagAddition: false,
};

export const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
    addFeatureFlagOnTop(state, action: PayloadAction<FeatureFlag>) {
      state.successfulFlagAddition = false;
      state.featureFlags.unshift(action.payload);
    },
  },
  extraReducers: builder => {
    // Get feature flags
    builder.addCase(fetchFeatureFlags.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchFeatureFlags.fulfilled, (state, action: PayloadAction<FeatureFlag[]>) => {
      state.loading = false;
      state.featureFlags = action.payload;
    });
    builder.addCase(fetchFeatureFlags.rejected, state => {
      state.loading = false;
      state.featureFlags = [];
    });
    // Add feature flags
    builder.addCase(addFeatureFlag.pending, state => {
      state.isLoadingAddFlag = true;
    });
    builder.addCase(addFeatureFlag.fulfilled, state => {
      state.isLoadingAddFlag = false;
      state.successfulFlagAddition = true;
    });
    builder.addCase(addFeatureFlag.rejected, state => {
      state.isLoadingAddFlag = false;
    });
    // Update feature flag
    builder.addCase(updateFeatureFlag, (state, action) => {
      state.featureFlags = state.featureFlags.map(featureFlag => {
        return featureFlag.id !== action.payload.id ? featureFlag : { ...featureFlag, ...action.payload };
      });
    });
  },
});

export const { reset, addFeatureFlagOnTop } = featureFlagsSlice.actions;
