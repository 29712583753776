import styles from '@/pages/configurations/countries-configurations/CountriesConfigurations.module.scss';
import Typography from '@/components/common/typography/Typography';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';
import Button from '@/components/common/button/Button';
import { Permissions } from '@/types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import PaymentMethodTopupModal from '@/components/configurations/payment-method-topup/PaymentMethodTopupModal';
import PaymentMethodGroupTopupModal from '@/components/configurations/payment-method-group-topup/PaymentMethodGroupTopupModal';

const PaymentMethodsTopUp = (): JSX.Element => {
  const { t } = useTranslation('configurations');
  const [open, setOpen] = useState(false);
  const [openGroup, setOpenGroup] = useState(false);

  return (
    <>
      <div className={styles.title}>
        <Typography variant='p1SemiBold'>{t('payment-method-topup.title')}</Typography>
        <UserPermissionsGuard permission={Permissions.countryConfigEdit}>
          <div className={styles.buttons}>
            <Button
              textTransform='uppercase'
              onClick={() => setOpenGroup(true)}
            >
              + {t('payment-method-topup.button-new-method-group')}
            </Button>
            <Button
              textTransform='uppercase'
              onClick={() => setOpen(true)}
            >
              + {t('payment-method-topup.button-new-method')}
            </Button>
          </div>
        </UserPermissionsGuard>
      </div>
      {open && <PaymentMethodTopupModal onClose={() => setOpen(false)} />}
      {openGroup && <PaymentMethodGroupTopupModal onClose={() => setOpenGroup(false)} />}
      <div className={styles.emptyCard}></div>
    </>
  );
};

export default PaymentMethodsTopUp;
