import authAxios from '@/auth/axios';
import { useCallback, useState, useMemo } from 'react';
import { useAppDispatch } from '@/lib/redux';

import { updateFeatureFlag } from '@/lib/redux/slices/feature-flags/actions';
import { type FeatureFlag } from '@/types/FeatureFlag';
import { errorToast } from '@/utils/toastMessage';

export const useFeatureFlagUpdate = (
  updateUrl: string,
  updateData: { flagId: string } | FeatureFlag,
): { isLoading: boolean; error?: unknown; fetchData: () => void } => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown>(null);

  const dispatch = useAppDispatch();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await authAxios.put<FeatureFlag>(updateUrl, updateData);
      dispatch(updateFeatureFlag(response.data));
    } catch (error) {
      errorToast();
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, updateUrl, updateData]);

  const result = useMemo(
    () => ({
      isLoading,
      error,
      fetchData,
    }),
    [isLoading, error, fetchData],
  );

  return result;
};
