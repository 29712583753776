import ConfirmationModal from '@/components/common/modals/confirmation/ConfirmModal';

interface Props {
  title: string;
  setIsConfirmationOpen: (isOpen: boolean) => void;
  fetchData: () => void;
}

const FeatureConfirmModal = ({ title, setIsConfirmationOpen, fetchData }: Readonly<Props>): JSX.Element => {
  const onDeny = (): void => {
    setIsConfirmationOpen(false);
  };

  const onConfirm = (): void => {
    fetchData();
    setIsConfirmationOpen(false);
  };

  return (
    <ConfirmationModal
      title={title}
      onDeny={onDeny}
      onConfirm={onConfirm}
    />
  );
};

export default FeatureConfirmModal;
