import authAxios from '@/auth/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { errorToast, successToast } from '@/utils/toastMessage';
import type PaymentMethodGroup from '@/types/PaymentMethodGroup';

export const savePaymentMethodGroupTopup = createAsyncThunk(
  'paymentMethodGroupTopup.savePaymentMethodGroupTopup',
  async (data: PaymentMethodGroup, { rejectWithValue }) => {
    try {
      const response = await authAxios.post('/topup/payment-method-groups', data);

      successToast('toast-messages.payment-method-created');

      return response.data as PaymentMethodGroup;
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);
